<template>
	<div class="page__block">
		<main class="body__block">
			<section class="section__item">
				<div class="start__block">
					<div class="content__block">
						<div class="start__inner">
							<a
								href="https://bf.sistema.ru/"
								target="_blank"
								class="start__prelogo"
							/>
							<router-link
								to="/"
								class="start__logo"
								target="_blank"
							/>
							<div class="start__content">
								<h1 class="start__title">
									#ЛИФТБЕЗОТКАЗА
								</h1>
								<div class="start__text m--text">
									<p>
										<b>от этих детей отказались родители, но <br>
											БФ «Система», «Лифт в будущее» и Фонд <br>
											«Волонтеры в помощь детям-сиротам» <br>
											работают без отказа – подготовят к <br>
											экзаменам c лучшими экспертами, <br>
											помогут сделать первые шаги в карьере.</b>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="section__item">
				<div class="about__block">
					<div class="content__block">
						<h4 class="section__title">
							О ПРОЕКТЕ
						</h4>
						<div class="about__content">
							<img
								:src="'/landings/lift-bez-otkaza/img/about/about-pic-1.png' | prodSrcImage"
								alt=""
								class="about__pic"
							>
							<div class="about__text m--text">
								<p>
									<b><span>дети-сироты</span> чаще всего не могут поступить в высшие учебные заведения,
										выбрать профессию мечты и даже думать о высоких карьерных перспективах.</b>
								</p>
							</div>
							<div class="about__info">
								<img
									:src="'/landings/lift-bez-otkaza/img/about/about-pic-2.png' | prodSrcImage"
									alt=""
									class="about__subpic"
								>
								<div class="about__subtext m--text">
									<p>
										мы собираем средства для реализации проекта по подготовке к выпускным и
										вступительным экзаменам детей-сирот из социальных учреждений.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="section__item">
				<div class="problem__block">
					<div class="content__block">
						<h4 class="section__title">
							ПРОБЛЕМА
						</h4>
						<div class="grid__group">
							<div class="grid__item">
								<div class="problem__item">
									<div class="problem__header">
										<img
											:src="'/landings/lift-bez-otkaza/img/problem/problem-pic-1.png' | prodSrcImage"
											alt=""
											class="problem__pic"
										>
										<h2 class="problem__title">
											Социальная <br>
											<span>дезориентация</span>
										</h2>
									</div>
									<div class="problem__text m--text">
										<p>
											<b>по мнению психологов, состояние детей-отказников характеризуются
												следующим примером:
												«Представьте, вы находитесь в аэропорту в незнакомом городе без
												документов и денег, не помните, как вас зовут и куда вам нужно
												лететь». С таким ощущением сироты живут зачастую всю жизнь.</b>
										</p>
									</div>
								</div>
							</div>
							<div class="grid__item">
								<div class="problem__item">
									<div class="problem__header">
										<img
											:src="'/landings/lift-bez-otkaza/img/problem/problem-pic-2.png' | prodSrcImage"
											alt=""
											class="problem__pic"
										>
										<h2 class="problem__title">
											<span>Высокий порог <br>
												входа</span> в <br>
											профессиональное <br>
											и высшее <br>
											образование
										</h2>
									</div>
									<div class="problem__text m--text">
										<p>
											<b>чаще всего поступают в вузы те, кто может позволить себе дорогих
												репетиторов и системное образование. Дети-отказники лишены такой
												возможности.</b>
										</p>
									</div>
								</div>
							</div>
							<div class="grid__item">
								<div class="problem__item">
									<div class="problem__header">
										<img
											:src="'/landings/lift-bez-otkaza/img/problem/problem-pic-3.png' | prodSrcImage"
											alt=""
											class="problem__pic"
										>
										<h2 class="problem__title">
											<span>отсутствие <br>
												веры</span> в себя
										</h2>
									</div>
									<div class="problem__text m--text">
										<p>
											<b>низкая самооценка, формируемая в среде социальных учреждений, влияет на
												объем притязаний даже очень
												талантливых сирот.</b>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="section__item">
				<div class="solution__block">
					<div class="content__block">
						<div class="solution__inner">
							<div class="solution__title">
								<h4 class="section__title">
									Решение
								</h4>
							</div>
							<div class="solution__content">
								<div class="solution__subtitle">
									<h4 class="section__title">
										ДАВАТЬ <span>УДОЧКУ,</span> <br>
										А НЕ РЫБУ
									</h4>
								</div>
								<div class="solution__text m--text">
									<p>
										<b>наше решение</b> – инвестировать не в материальные блага, а в образование и
										профессиональное развитие. Мы предлагаем возможность поднять уровень детей,
										оставшихся без попечения родителей, до самостоятельной сдачи ЕГЭ и ОГЭ и, как
										следствие, получение высшего и среднего специального образования.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="section__item">
				<div class="step__block">
					<div class="content__block">
						<div class="step__inner">
							<h4 class="section__title">
								Механика проекта
							</h4>
							<div class="step__content">
								<div class="step-pic_group">
									<div class="step-pic__item" />
									<div class="step-pic__item" />
									<div class="step-pic__item" />
								</div>
								<div class="step__group">
									<div class="step__item">
										<div class="step__text m--text">
											<p>
												<b>фонд-партнер определяет целевые группы учеников, отбирает
													преподавателей для подготовки детей-сирот, используя контент «Лифт в
													будущее», отчитывается о реализации благотворительного
													пожертвования;</b>
											</p>
										</div>
									</div>
									<div class="step__item">
										<div class="step__text m--text">
											<p>
												<b>благотворительный фонд «Система» обеспечивает партнера-контрагента
													дополнительным образовательным контентом для учащихся, разрабатывает
													мотивационную стипендиальную программу для педагогов; </b>
											</p>
										</div>
									</div>
									<div class="step__item">
										<div class="step__text m--text">
											<p>
												<b>контроль над расходованием средств осуществляется
													финансово-юридической службой БФ «Система». </b>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="section__item">
				<div class="action__block">
					<div class="content__block">
						<div class="action__wrap">
							<div class="action__content">
								<h1 class="start__title">
									#ЛИФТБЕЗОТКАЗА
								</h1>
								<!--                                <h3 class="action__pretitle">-->
								<!--                                    <a href="/staticfiles/media/program-lift-bez-otkaza.pdf" target="_blank" class="link__item" style="color: #FF8E0D !important">Положение о программе</a>-->
								<!--                                </h3>-->
								<h3 class="action__pretitle">
									Перевести средства
								</h3>
								<div class="action-button__month">
									<div
										class="action-button__item"
										:class="{'m--active': !payMonthly}"
										@click="payMonthly = false"
									>
										единовременно
									</div>
									<div
										class="action-button__item"
										:class="{'m--active': payMonthly}"
										@click="payMonthly = true"
									>
										ежемесячно
									</div>
								</div>
								<div class="action__monthly m--text">
									<p>
										<b>Отправляя ежемесячно комфортную для вас сумму на программу поддержки
											детей-сирот, вы помогаете нам системно решать проблемы образования, просвещения
											и профессионального развития.</b>
									</p>
								</div>
								<div class="action-button__number">
									<div
										class="action-button__item"
										:class="{'m--active': payForOneLesson}"
										@click="payForOneLesson = true; payForTwoLessons = false; payForEightLessons = false; payAnotherSum = false"
									>
										1 занятие
									</div>
									<div
										class="action-button__item"
										:class="{'m--active': payForTwoLessons}"
										@click="payForOneLesson = false; payForTwoLessons = true; payForEightLessons = false; payAnotherSum = false"
									>
										2 занятия
									</div>
									<div
										class="action-button__item"
										:class="{'m--active': payForEightLessons}"
										@click="payForOneLesson = false; payForTwoLessons = false; payForEightLessons = true; payAnotherSum = false"
									>
										8 занятий
									</div>
									<div
										class="action-button__item"
										:class="{'m--active': payAnotherSum}"
										@click="payForOneLesson = false; payForTwoLessons = false; payForEightLessons = false; payAnotherSum = true"
									>
										другая сумма
									</div>
								</div>
								<div class="action-tab__group">
									<div
										class="action-tab__item"
										:class="{'m--active': payForOneLesson}"
									>
										<div class="action-tab__text m--text">
											<p>
												<b>
													1 занятие = {{ oneLessonCost }} рублей. <br>
													Стоимость индивидуального занятия с преподавателем по выбранному
													предмету для воспитанника социального учреждения.
												</b>
											</p>
										</div>
									</div>
									<div
										class="action-tab__item"
										:class="{'m--active': payForTwoLessons}"
									>
										<div class="action-tab__text m--text">
											<p>
												<b>
													2 занятия = {{ oneLessonCost * 2 }} рублей. <br>
													Неделя индивидуальных занятий для воспитанника социального
													учреждения.
												</b>
											</p>
										</div>
									</div>
									<div
										class="action-tab__item"
										:class="{'m--active': payForEightLessons}"
									>
										<div class="action-tab__text m--text">
											<p>
												<b>
													8 занятий = {{ oneLessonCost * 8 }} рублей. <br>
													Целый месяц по выбранному предмету для воспитаника социального
													учреждения.
												</b>
											</p>
										</div>
									</div>
									<div
										class="action-tab__item"
										:class="{'m--active': payAnotherSum}"
									>
										<div class="field__item">
											<input
												v-model.trim="$v.help.money.$model"
												type="text"
												class="field__value"
												placeholder="сумма"
											>
											<span
												class="help-block form-prompt"
											>* Допустимая сумма от 1 до 5500 рублей</span>
											<span
												v-if="$v.help.money.$error"
												class="help-block form-error"
											>
												{{
													!$v.help.money.required ? 'Это обязательное поле' :
													!$v.help.money.numeric ? 'Введите корректную сумму' : 'Ошибка поля' ||
														!$v.help.money.forLiftWR ? `Введите сумму от 1 до 5500` : 'Ошибка поля'
												}}
											</span>
										</div>
									</div>
								</div>
								<div class="field__group">
									<div class="field__item">
										<input
											v-model="help.phone"
											v-mask="'+7(###)###-##-##'"
											type="text"
											class="field__value"
											placeholder="телефон"
										>
										<span
											v-if="$v.help.phone.$error"
											class="help-block form-error"
										>
											Введите корректный номер телефона
										</span>
									</div>
									<div class="field__item">
										<input
											v-model.trim="help.email"
											type="text"
											class="field__value"
											:isError="$v.help.email.$error"
											placeholder="email"
										>
										<span
											v-if="$v.help.email.$error"
											class="help-block form-error"
										>
											{{
												!$v.help.email.required ? 'Это обязательное поле' :
												!$v.help.email.email ? 'Введите корректный e-mail (на английском)' :
												!$v.help.email.maxLength ? `Не более ${$v.help.email.maxLength.max} символов` : 'Ошибка поля'
											}}
										</span>
									</div>
									<div class="field__item">
										<div class="checkbox__group">
											<label class="checkbox__item">
												<input
													v-model="$v.agree.isPersonal.$model"
													type="checkbox"
													@change="$v.agree.isPersonal.$touch()"
												>
												<span>соглашаюсь на обработку <a
													href="/staticfiles/media/privacy-lift-bez-otkaza.pdf.pdf"
													target="_blank"
												>персональных данных</a></span>
												<div
													v-if="$v.agree.isPersonal.$error"
													class="help-block form-error"
												>
													Необходимо подтверждение
												</div>
											</label>
											<label class="checkbox__item">
												<input
													v-model="$v.agree.isAgree.$model"
													type="checkbox"
													@change="$v.agree.isAgree.$touch()"
												>
												<span>соглашаюсь с условиями <a
													href="/media/oferta_lift-bez-otkaza.pdf"
													target="_blank"
												>публичной оферты</a></span>
												<div
													v-if="$v.agree.isAgree.$error"
													class="help-block form-error"
												>
													Необходимо подтверждение
												</div>
											</label>
											<label class="checkbox__item">
												<input
													v-model="$v.agree.isProgram.$model"
													type="checkbox"
													@change="$v.agree.isProgram.$touch()"
												>
												<span>ознакомлен с <a
													href="/staticfiles/media/program-lift-bez-otkaza.pdf"
													target="_blank"
												>текстом программы</a></span>
												<div
													v-if="$v.agree.isProgram.$error"
													class="help-block form-error"
												>
													Необходимо подтверждение
												</div>
											</label>
										</div>
									</div>
									<div class="field__item">
										<button
											v-if="!isSended"
											class="button__item m--full"
											@click="sendHelp"
										>
											Я хочу
											помочь!
										</button>
										<div
											v-else
											class="button__item m--full button-preloader"
										>
											<div class="button-preloader-icons">
												<div class="button-preloader-icon" />
												<div class="button-preloader-icon" />
												<div class="button-preloader-icon" />
											</div>
										</div>
									</div>
								</div>
								<div class="field__item">
									<button
										class="button__item m--full"
										@click="openQR"
									>
										Показать QR-код
									</button>
								</div>
								<div
									class="start__text m--text"
									style="margin-top: 0;margin-bottom: 30px;text-align: center; font-size: 18px"
								>
									<p>
										Осуществляя пожертвование с использованием qr-кода подтверждаю, что ознакомился
										<a
											:href="'/media/oferta_lift-bez-otkaza.pdf' | prodSrcImage"
											target="_blank"
											style="color: white; text-decoration: none !important; border-color: white"
										>с условиями программы</a> и предоставляю согласие
										<a
											:href="'/media/privacy-lift-bez-otkaza.pdf.pdf' | prodSrcImage"
											target="_blank"
											style="color: white; text-decoration: none !important; border-color: white"
										>
											на обработку персональных данных
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>

		<footer class="footer__block">
			<div class="content__block">
				<div class="grid__group">
					<div class="grid__item">
						<router-link
							to="/"
							class="footer__logo"
						/>
						<div class="social__group">
							<a
								href="https://www.instagram.com/lift2future/?igshid=1n01c5im6d6ct"
								target="_blank"
								class="social__item"
							>
								<img
									:src="'/landings/lift-bez-otkaza/img/icons/icon-social-instagram-x2.png' | prodSrcImage"
									alt=""
								>
							</a>
							<a
								href="https://vk.com/lift2future"
								target="_blank"
								class="social__item"
							>
								<img
									:src="'/landings/lift-bez-otkaza/img/icons/icon-social-vk-x2.png' | prodSrcImage"
									alt=""
								>
							</a>
						</div>
					</div>
				</div>
				<div
					v-if="showPromptModal"
					class="modal"
					@mousedown="clickOutModalPay"
					@keydown.esc.stop.prevent="modalClosePay()"
				>
					<div class="modal-container">
						<div class="modal-inner">
							<div class="modal-body">
								<div
									class="modal-close"
									data-modal-close
									@click.stop="modalClosePay"
								/>
								<div class="modal-content">
									<div class="center">
										<h3
											class="modal-title"
											style="text-align: center"
										>
											Внимание:
										</h3>
										<p style="text-align: center">
											для подтверждения ежемесячного платежа активируйте
											чек-бокс
											<b> "Запомнить данные этой карты" </b> на странице оплаты
										</p>
										<button
											v-if="!isSendedModalButton"
											class="button__item m--full"
											style="margin-top: 20px"
											@click="sendHelp"
										>
											Продолжить
										</button>
										<div
											v-else
											class="button__item m--full button-preloader"
											style="margin-top: 20px"
										>
											<div class="button-preloader-icons">
												<div class="button-preloader-icon" />
												<div class="button-preloader-icon" />
												<div class="button-preloader-icon" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="$route.query.name === 'declined'"
					class="modal"
					@mousedown="clickOutModal"
					@keydown.esc.stop.prevent="modalClose()"
				>
					<div class="modal-container">
						<div class="modal-inner">
							<div class="modal-body">
								<div
									class="modal-close"
									data-modal-close
									@click.stop="modalClose"
								/>
								<div class="modal-content">
									<div class="center">
										<img
											:src="'/img/icons/icon-alert-success.svg' | prodSrcImage"
											class="alert-icon"
										>
										<br>
										<h3 class="modal-title">
											Вы отменили подписку на платежи
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="$route.query.name === 'success'"
					class="modal"
					@mousedown="clickOutModal"
					@keydown.esc.stop.prevent="modalClose()"
				>
					<div class="modal-container">
						<div class="modal-inner">
							<div class="modal-body">
								<div
									class="modal-close"
									data-modal-close
									@click.stop="modalClose"
								/>
								<div class="modal-content">
									<div class="center">
										<img
											:src="'/img/icons/icon-alert-success.svg' | prodSrcImage"
											class="alert-icon"
										>
										<h3 class="modal-title">
											Оплата прошла успешно
										</h3>
										<br>
										<p>Мы получили Ваш платеж, большое спасибо!</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="$route.query.name === 'fail'"
					class="modal"
					@mousedown="clickOutModal"
					@keydown.esc.stop.prevent="modalClose()"
				>
					<div class="modal-container">
						<div class="modal-inner">
							<div class="modal-body">
								<div
									class="modal-close"
									data-modal-close
									@click.stop="modalClose"
								/>
								<div class="modal-content">
									<div class="center">
										<img
											:src="'/img/icons/icon-alert-error.svg' | prodSrcImage"
											class="alert-icon"
										>
										<h3 class="modal-title">
											Оплата не прошла
										</h3>
										<br>
										<p>Платеж не прошёл, Вы можете повторить попытку через форму перевода</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
import {email, required, numeric, sameAs, maxLength} from 'vuelidate/lib/validators'
import {Phone, forLiftWR} from '@/assets/scripts/custom_validators'
import Tb from '@/api/teachbase'

export default {
    name: 'LiftWithoutRefusal',
    data: () => ({
        help: {
            mail: '',
            phone: '',
            money: '',
        },
        agree: {
            isPersonal: false,
            isAgree: false,
            isProgram: false
        },
				oneLessonCost: 585,
        isSended: false,
        openModal: false,
        payMonthly: false,
        payForOneLesson: true,
        payForTwoLessons: false,
        payForEightLessons: false,
        payAnotherSum: false,
        showPromptModal: false,
        isSendedModalButton: false
    }),
    validations: {
        help: {
            money: {required, numeric, forLiftWR},
            email: {required, email, maxLength: maxLength(320)},
            phone: {Phone}
        },
        agree: {
            isPersonal: {
                sameAs: sameAs(() => true)
            },
            isAgree: {
                sameAs: sameAs(() => true)
            },
            isProgram: {
                sameAs: sameAs(() => true)
            }
        }
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
    },
    methods: {
        clickOutModal(event) {
            if (!event.target.closest('.modal-body')) {
                this.modalClose()
            }
        },
        clickOutModalPay(event) {
            if (!event.target.closest('.modal-body')) {
                this.modalClosePay()
            }
        },
        modalClose() {
            this.$router.push('/bez-otkaza')
        },
        modalClosePay() {
            this.showPromptModal = false
        },
        sendHelp() {
            if (this.payForOneLesson) this.help.money = this.oneLessonCost
            else if (this.payForTwoLessons) this.help.money = this.oneLessonCost * 2
            else if (this.payForEightLessons) this.help.money = this.oneLessonCost * 8
            this.$v.$touch()
            if (this.$v.$invalid) {
                console.log('Help form is error')
            } else if (!this.isSended || !this.isSendedModalButton) {
                if (this.payMonthly && !this.showPromptModal) {
                    this.showPromptModal = true
                } else if (this.showPromptModal && this.payMonthly) {
                    this.isSendedModalButton = true
                    Tb.sendPayment({
                        auto_deposit: this.payMonthly,
                        amount: this.help.money,
                        phone: this.help.phone,
                        email: this.help.email
                    })
                        .then(res => {
                            window.location.href = res.data
                            this.$v.help.$reset()
                            this.$v.agree.$reset()
                            this.help = {
                                money: '',
                                email: '',
                            }
                            this.agree = {
                                isPersonal: false,
                                isAgree: false,
                                isProgram: false
                            }
                        })
                        .catch(error => {
                            if (error.response.data.error_text) this.modalOpen('ambsdr-2-Fail', error.response.data.error_text)
                            else if (error.response.data.errorMessage) this.modalOpen('ambsdr-2-Fail', error.response.data.errorMessage)
                            else this.modalOpen('ambsdr-2-Fail', 'Введите корректную сумму')
                        })
                        .finally(() => this.isSendedModalButton = false)
                } else if (!this.payMonthly) {
                    this.isSended = true
                    Tb.sendPayment({
                        auto_deposit: this.payMonthly,
                        amount: this.help.money,
                        phone: this.help.phone,
                        email: this.help.email
                    })
                        .then(res => {
                            window.location.href = res.data
                            this.$v.help.$reset()
                            this.$v.agree.$reset()
                            this.help = {
                                money: '',
                                email: '',
                            }
                            this.agree = {
                                isPersonal: false,
                                isAgree: false,
                                isProgram: false
                            }
                        })
                        .catch(error => {
                            if (error.response.data.error_text) this.modalOpen('ambsdr-2-Fail', error.response.data.error_text)
                            else if (error.response.data.errorMessage) this.modalOpen('ambsdr-2-Fail', error.response.data.errorMessage)
                            else this.modalOpen('ambsdr-2-Fail', 'Введите корректную сумму')
                        })
                        .finally(() => this.isSended = false)
                }
            }
        },
        openMainMenu() {
            this.modalOpen('mainMenu')
        },
        openQR() {
            this.$v.agree.$touch()
            if (this.$v.agree.$invalid) {
                console.log('Help form is error')
            } else {
                this.modalOpen('helpLift')
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../assets/landings/lift-bez-otkaza/styles/compiled";
@import "../../assets/landings/lift-bez-otkaza/styles/layout";
@import "../../assets/landings/lift-bez-otkaza/styles/reset";
@import "../../assets/landings/lift-bez-otkaza/styles/styles";
@import "../../assets/landings/lift-bez-otkaza/styles/modal";
@import "../../assets/landings/lift-bez-otkaza/styles/variables";

.page__block {
    margin-top: -20px;
    @media (max-width: 767px) {
        margin-top: -80px;
    }
}

.form-prompt {
    font-size: 12px;
}
</style>
